import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { URL_BASE, DEFTKN, URL_HOSTING } from "../constants";
import { useSearchParams } from "react-router-dom";

const PaymentStatus = () => {
  const [params] = useSearchParams();

  useEffect(() => {
    checkParamasFromUrl();
  }, []);

  const checkParamasFromUrl = () => {
    const checkoutId = params.get("checkoutId");
    //useSearchParams no recibe parametros
    if (checkoutId === null) {
      let useRNative = false;
      const checkoutIdFromLocal = localStorage.getItem("checkoutId");
      checkPayStatus(checkoutIdFromLocal, useRNative);
      //useSearchParams recibe parametro usando react native
    } else {
      let useRNative = true;
      checkPayStatus(checkoutId, useRNative);
    }
  };

  const checkPayStatus = async (checkoutId, useRNative) => {
    fetch(`${URL_BASE}api/copyandpay/?checkoutId=${checkoutId}`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${DEFTKN}`,
      },
    })
      .then((response) => {
        console.log(
          "--- Respuesta para revisar el status, a ver si fue pagado ----"
        );
        console.log(response);
        console.log("");
        if (response.status == "200") {
          response.json().then((res) => {
            const successfullyProcessedTransactions = new RegExp('^(000\.000\.|000\.100\.1|000\.[36])');
            const successfullyProcessedTransactionsThatShouldBeManuallyReviewed  = new RegExp('^(000\.400\.0[^3]|000\.400\.[0-1]{2}0)');
            if (successfullyProcessedTransactions.test(res.data.result.code) || 
                successfullyProcessedTransactionsThatShouldBeManuallyReviewed.test(res.data.result.code)) {
              console.log("");
              {
                useRNative === true
                  ? Swal.fire({
                      title: "¡Gracias, su pago se ha procesado correctamente!",
                      icon: "success",
                      html: "",
                      showCloseButton: false,
                      showCancelButton: false,
                      focusConfirm: false,
                      //cancelButtonAriaLabel: 'Thumbs down'
                    })
                  : Swal.fire({
                      title: "¡Gracias, su pago se ha procesado correctamente!",
                      icon: "success",
                      html: "",
                      showCloseButton: false,
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonText:`<a style="text-decoration:none;color:#FFFFFF" href=${URL_HOSTING}>Entendido</a>`,
                    });
              }
            } else {

              Swal.fire({
                title: "Lo sentimos, su pago no pudo ser procesado",
                icon: "error",
                html: `<ul>
                              <p>Prueba las siguientes opciones:</p>
                                  <li> </li>
                                  <li> Revisa si tu forma de pago está actualizada. Si no lo está, actualízala en el centro de pagos y, luego, prueba volver a realizar la compra.</li>
                                  <li> Asegúrate de tener dinero suficiente en la cuenta para realizar la compra.</li>
                                  <li> Si no funcionan las opciones anteriores, comunícate con el banco para saber si existe algún problema con la cuenta.</li>
                                  <li> Prueba realizar la compra con otra forma de pago.</li>
                              </ul>`,
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText:
                `<a style="text-decoration:none;color:#FFFFFF" href=${URL_HOSTING}>Entendido</a>`,
                //cancelButtonAriaLabel: 'Thumbs down'
              });              

            }
            // if (res.data.result.code === "000.100.110") {
            //   //Succed payment
            //   //Condicional en caso de ingresar desde la app react native
            //   //console.log(JSON.stringify(res.data));
            //   console.log("");
            //   {
            //     useRNative === true
            //       ? Swal.fire({
            //           title: "¡Gracias, su pago se ha procesado correctamente!",
            //           icon: "success",
            //           html: "",
            //           showCloseButton: false,
            //           showCancelButton: false,
            //           focusConfirm: false,
            //           //cancelButtonAriaLabel: 'Thumbs down'
            //         })
            //       : Swal.fire({
            //           title: "¡Gracias, su pago se ha procesado correctamente!",
            //           icon: "success",
            //           html: "",
            //           showCloseButton: false,
            //           showCancelButton: false,
            //           focusConfirm: false,
            //           confirmButtonText:`<a style="text-decoration:none;color:#FFFFFF" href=${URL_HOSTING}>Entendido</a>`,
            //         });
            //   }
            // }
          });
          localStorage.removeItem("checkoutId");
        } else {
          {
            useRNative
              ? Swal.fire({
                  title: "Lo sentimos, su pago no pudo ser procesado",
                  icon: "error",
                  html: `<ul>
                                <p>Prueba las siguientes opciones:</p>
                                    <li> </li>
                                    <li> Revisa si tu forma de pago está actualizada. Si no lo está, actualízala en el centro de pagos y, luego, prueba volver a realizar la compra.</li>
                                    <li> Asegúrate de tener dinero suficiente en la cuenta para realizar la compra.</li>
                                    <li> Si no funcionan las opciones anteriores, comunícate con el banco para saber si existe algún problema con la cuenta.</li>
                                    <li> Prueba realizar la compra con otra forma de pago.</li>
                                </ul>`,
                  showCloseButton: false,
                  showCancelButton: false,
                  focusConfirm: false,
                })
              : Swal.fire({
                  title: "Lo sentimos, su pago no pudo ser procesado",
                  icon: "error",
                  html: `<ul>
                                <p>Prueba las siguientes opciones:</p>
                                    <li> </li>
                                    <li> Revisa si tu forma de pago está actualizada. Si no lo está, actualízala en el centro de pagos y, luego, prueba volver a realizar la compra.</li>
                                    <li> Asegúrate de tener dinero suficiente en la cuenta para realizar la compra.</li>
                                    <li> Si no funcionan las opciones anteriores, comunícate con el banco para saber si existe algún problema con la cuenta.</li>
                                    <li> Prueba realizar la compra con otra forma de pago.</li>
                                </ul>`,
                  showCloseButton: false,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText:
                  `<a style="text-decoration:none;color:#FFFFFF" href=${URL_HOSTING}>Entendido</a>`,
                  //cancelButtonAriaLabel: 'Thumbs down'
                });
          }
        }
      })
      .catch((err) => {
        console.log(err, "error");
        {
          useRNative
            ? Swal.fire({
                icon: "error",
                title: "Lo sentimos, el pago no pudo ser procesado",
                text: "Hubo un error al establecer conexion con el servidor",
                footer: "",
              })
            : Swal.fire({
                icon: "error",
                title: "Lo sentimos, el pago no pudo ser procesado",
                text: "Hubo un error al establecer conexion con el servidor",
                confirmButtonText: 
                `<a style="text-decoration:none;color:#FFFFFF" href=${URL_HOSTING}>Entendido</a>`,
                footer: "", 
              });
        }
      });
  };

  return (
    <>
      <div></div>
    </>
  );
};

export default PaymentStatus;
