import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import NumberFormat from "react-number-format";
import { URL_BASE, DEFTKN } from "../constants";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FF0040",
    color: "#FFFFFF",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(11),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class Step2 extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      cuenta: null,
      concepts: {},
      amount: null,
      showHint: false,
      checkoutIdPaymentStatus: {},
    };
  }

  componentDidMount() {
    //this.getCheckoutId();
    localStorage.removeItem("checkoutId");
    this.forceUpdate();
    this.getAccount();
    if (!this.loguedIn()) {
      this.requestService(
        "concepts",
        "listaservicios",
        this.props.idcampus,
        this.props.idniveleducativo
      );
    }
  }

  getAccount = () => {
    if (this.loguedIn()) {
      fetch(
        `${URL_BASE}api/estadocuenta?jsonParameters={"nombreSolicitud":"getestadocuenta"}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          this.setState({ cuenta: response, showHint: true }, () => {
            this.props.setAccount(response);
          });
        });
    }
  };

  loguedIn = () => {
    return !!localStorage.getItem("usr");
  };

  requestService = (field, solicitud, idcampus, idNivEdu) => {
    var query = `"nombreSolicitud":"${solicitud}"`;
    switch (field) {
      default:
      case "concepts": {
        query += `,"idcampus":${idcampus}, "idniveleducativo":${idNivEdu}`;
        break;
      }
    }
    fetch(`${URL_BASE}api/campus?jsonParameters={${query}}`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${DEFTKN}`,
      },
    }).then((response) => {
      response.json().then((res) => {
        this.setState({ [field]: res });
      });
    });
  };

  handleChange = async (field, event) => {
    let value = event.target.value;
    await this.props.changeState(field, value);
    if (field === "concepto") {
      setTimeout(() => {
        if (this.loguedIn() && this.state.cuenta != null) {
          let amount = this.state.cuenta.Cuentas[value].balance;
          this.props.changeState("cantidad", amount);
        } else {
          var amount = 0;
          for (let i = 0; i <= this.state.concepts.length - 1; i++) {
            if (
              this.state.concepts[i].Nombre_Servicio ===
              this.props.state.concepto
            ) {
              amount = this.state.concepts[i].Costo;
            }
          }
          this.props.changeState("cantidad", amount);
        }
      }, 200);
    }
  };

  hasError = (field) => {
    if (this.props.formSubmitted) {
      if (this.props.state[field] === "") {
        return true;
      }
    }
    return false;
  };

  getConceptos = () => {
    if (this.loguedIn() && this.state.cuenta != null) {
      return this.state.cuenta.Cuentas.map((d, i) => (
        <MenuItem selected key={i} value={i}>
          {d.Nombre_Cuenta}
        </MenuItem>
      ));
    }

    if (!this.loguedIn()) {
      return Object.keys(this.state.concepts).map((index) => (
        <MenuItem
          key={index}
          value={this.state.concepts[index].Nombre_Servicio}
        >
          {this.state.concepts[index].Nombre_Servicio}
        </MenuItem>
      ));
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.checkoutIdPaymentStatus ? null : (
          <Grid container className={classes.container}>
            <Grid item container xs={12} lg={6}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <p>Haz click aquí</p>
                    <p style={{ marginLeft: 30 }}>
                      <b>&#8595;</b>
                    </p>
                  </React.Fragment>
                }
                placement="top-end"
                open={this.state.showHint}
              >
                <TextField
                  select
                  error={this.hasError("concepto")}
                  fullWidth
                  label="Concepto"
                  className={classes.textField + " " + classes.formControl}
                  value={this.props.state.concepto}
                  onChange={this.handleChange.bind(this, "concepto")}
                  onClick={() => this.setState({ showHint: false })}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  helperText={
                    this.hasError("concepto")
                      ? "Este campo es requerido"
                      : "Selecciona un concepto"
                  }
                  margin="normal"
                >
                  {this.getConceptos()}
                </TextField>
              </HtmlTooltip>
            </Grid>
            <Grid item container xs={12} lg={6}>
              <TextField
                className={classes.formControl}
                label="Cantidad a pagar"
                fullWidth
                error={this.hasError("cantidad")}
                helperText={
                  this.hasError("apellidos")
                    ? "Este campo es requerido"
                    : "Cantidad a pagar"
                }
                value={this.props.state.cantidad}
                onChange={this.handleChange.bind(this, "cantidad")}
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

Step2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Step2);
