import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import Session from './Session';
import logo from './logo.svg';

import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';

const styles = theme => ({
    backButton: {
        marginRight: theme.spacing.unit,
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

const defaultState = {
    activeStep: 0,
    formSubmitted: false,
    formvalid: true,
    account: null,
    step1: {
        nombres: '',
        nivel: '',
        apellidos: '',
        correo: '',
        telefono: '',
        modalidad: '',
        campus: '',
        carrera: '',
        idcampus: '',
        idniveleducativo: ''
    },
    step2: {
        concepto: '',
        cantidad: ''
    },
    step3: {
        accept: false,
        paymethod: 'tarjeta',
        paymentType: ''
    },
}

class HorizontalLabelPositionBelowStepper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...defaultState
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.loguedIn !== nextProps.loguedIn) {
            this.handleReset();
        }
    }

    updateModel = (step, field, value) => {
        this.setState({
            [step]: {
                ...this.state[step],
                [field]: value
            }
        });
    }

    getSteps = () => {
        var steps = ['Información de pago', 'Forma de pago', 'Confirmación', 'Realizar pago'];
        if (this.loguedIn()) steps.shift();
        return steps;
    }

    loguedIn = () => {
        return !!localStorage.getItem('usr');
    }

    setAccount = (account) => {
        this.setState({ account });
    };

    getStepContent(stepIndex) {
        let indx = this.loguedIn() ? stepIndex + 1 : stepIndex;

        switch (indx) {
            case 0:
                return (
                    <Step1
                        changeState={this.updateModel.bind(this, 'step1')}
                        formSubmitted={this.state.formSubmitted}
                        state={this.state.step1}
                    />
                );
            case 1:
                return (
                    <Step2
                        changeState={this.updateModel.bind(this, 'step2')}
                        formSubmitted={this.state.formSubmitted}
                        idniveleducativo={this.state.step1.idniveleducativo}
                        idcampus={this.state.step1.idcampus}
                        setAccount={this.setAccount}
                        state={this.state.step2}
                    />
                );
            case 2:
                return (
                    <Step3
                        step1={this.state.step1}
                        step2={this.state.step2}
                        formSubmitted={this.state.formSubmitted}
                        handleChange={this.updateModel.bind(this, 'step3')}
                        account={this.state.account}
                        accept={this.state.step3.accept}
                        paymethod={this.state.step3.paymethod}
                        paymentType={this.state.step3.paymentType}
                    />
                );
            case 3:
                return (
                    <Step4
                        step1={this.state.step1}
                        step2={this.state.step2}
                        step3={this.state.step3}
                        account={this.state.account}
                    />
                );
            default:
                return 'Unknown stepIndex';
        }
    }

    handleClickVariant = (msg, variant) => () => {
        // variant could be success, error, warning or info
        this.props.enqueueSnackbar(msg, { variant });
    };

    verify(step) {
        let model = this.state[step];
        var well = Object.keys(model).every(e => model[e] !== '');
        if (model.telefono && model.telefono.length !== 10) {
            this.handleClickVariant('El teléfono tiene un formato invalido', 'warning');
            return false;
        }
        return well;
    }
    verifyStep3() {
        //console.log('verifyStep3')
        console.log('')
        const { accept, paymethod, paymentType } = this.state.step3;
        if (accept === true) {
            //console.log('paymethod: ' + paymethod)
            if (paymethod === 'tarjeta') {
                //console.log('paymethod === tarjeta: ' + paymethod)
                //console.log('paymentType.length: ' + paymentType.length)
                if (paymentType.length > 0) {
                    return true;
                }                
            } else return true;

            return true;
        }        
        // if (accept === true && paymentType.length > 0) {
        //     return true;
        // }
        return false;
    }

    handleNext = () => {
        //console.log('handleNext')
        let formvalid = true;
        let formSubmitted = true;
        let step = this.loguedIn() ? this.state.activeStep + 1 : this.state.activeStep;
        let newStep = this.state.activeStep;
        // let x = this.refs.step1.getState();
        switch (step) {
            case 0:
                formvalid = this.verify('step1');
                break;
            case 1:
                formvalid = this.verify('step2');
                break; 
            case 2:
                formvalid = this.verifyStep3();
                //console.log('formvalid: ' + formvalid);
                break;
            case 3:
                formvalid = false;
                this.handleReset();
                return;
            default:
        }

        if (formvalid) {
            newStep = newStep + 1;
            formSubmitted = false;
        }

        this.setState({
            formvalid,
            formSubmitted,
            activeStep: newStep
        });
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState(defaultState);
    };

    render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;

        return (
            <>
                <div className="App">
                    <header className="App-header">
                        <div className="App-header-logo">
                            <img src={logo} className="App-logo" alt="logo" />
                        </div>
                        <div className="App-header-info">
                            <h3>Sistema de pagos de la <strong>Universidad America Latina</strong></h3>
                            <Session change={this.sessionChange} />
                        </div>
                    </header>
                </div>
                <div className={classes.root}>
                    <div className="Form-info">
                        <h1>Pago en Línea</h1>
                        <p>Complete la siguiente información, todos los campos son requeridos.</p>
                    </div>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className="Form-content">
                        {this.state.activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={this.handleReset}>Reset</Button>
                            </div>
                        ) : (
                            <div className="Form-container">
                                {this.getStepContent(activeStep)}
                                <div className="Form-buttons">
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={this.handleBack}
                                        className={classes.backButton}
                                    >
                                        Atras
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                                        {activeStep === steps.length - 1 ? 'Iniciar otro pago' : 'Siguiente'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

HorizontalLabelPositionBelowStepper.propTypes = {
    classes: PropTypes.object,
};

export default withSnackbar(withStyles(styles)(HorizontalLabelPositionBelowStepper));
