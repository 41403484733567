import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import ScriptTag from "react-script-tag";
import { Helmet } from "react-helmet";
import { URL_BASE, URL_CARDPAY, DEFTKN, URL_HOSTING } from "../../constants";

const styles = (theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class CardPay extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      scr: null,
      timer: false,
      checkoutIdd: "",
    };
  }

  loguedIn = function () {
    return !!localStorage.getItem("usr");
  };

  getIdCuenta = function () {
    if (this.loguedIn() && this.props.account) {
      return this.props.account.Cuentas[this.props.step2.concepto].id_Cuenta;
    }
    return -1; // Cuando no está logueado
  };

  getConcepto = function () {
    if (this.loguedIn() && this.props.account) {
      return this.props.account.Cuentas[this.props.step2.concepto]
        .Nombre_Cuenta;
    }
    return this.props.step2.concepto;
  };

  getToken = function () {
    if (this.loguedIn() && this.props.account) {
      return localStorage.getItem("tkn");
    }
    return DEFTKN;
  };

  getData = function () {
    if (this.loguedIn()) {
      var data = {
        id_persona: this.props.account ? this.props.account.id_Persona : -1,
        id_alumno: this.props.account.Cuentas[0].id_Alumno,
        id_cuenta: this.getIdCuenta(),
        checkoutId: "Checkoutid_Api",
        amount: this.props.step2.cantidad,
        currency: "MXN",
        paymentType: "CD",
      };
      return data;
    } else {
      data = {
        nombre: this.props.step1.nombres,
        apellidos: this.props.step1.apellidos,
        correo: this.props.step1.correo,
        telefono: this.props.step1.telefono,
        
        nivel: this.props.step1.nivel,
        modalidad: this.props.step1.modalidad,
        campus: this.props.step1.campus,
        idcampus: this.props.step1.idcampus,
        idniveleducativo: this.props.step1.idniveleducativo,

        carrera: this.props.step1.carrera,

        concepto: this.props.step2.concepto,
        amount: this.props.step2.cantidad,
        checkoutId: "Checkoutid_Api",
        currency: "MXN",
        paymentType: "CD",
      };
      return data;
    }
  };

  getLabels = function () {
    if (this.props.account) {
      return [];
    }
    return [
      {
        Etiqueta: "nombre",
        Valor: this.props.step1.nombres + " " + this.props.step1.apellidos,
      },
      {
        Etiqueta: "correo",
        Valor: this.props.step1.correo,
      },
      {
        Etiqueta: "telefono",
        Valor: this.props.step1.telefono,
      },
      {
        Etiqueta: "college",
        Valor:
          this.props.step1.nivel +
          " - " +
          this.props.step1.carrera +
          " - " +
          this.props.step1.modalidad +
          " - " +
          this.props.step1.campus,
      },
    ];
  };

  callPayModule() {
    if (this.state.src != null) {
      return;
    }
    let info = this.getData();
    fetch(`${URL_BASE}api/copyandpay`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      body: JSON.stringify(info),
    })
      .then(function (response) {
        return response.text();
      })
      .then((data) => {
        let res = JSON.parse(data);
        //console.log(JSON.stringify(res)); 
        this.setState({ checkoutIdd: res.data.CheckoutId });
        localStorage.setItem("checkoutId", res.data.CheckoutId);
      })
      .catch((err) => {
        if (typeof err === "object") {
          const message500 =
            "Hubo un error en el servidor, intentalo más tarde.";
          this.props.enqueueSnackbar(message500, { variant: "warning" });
        } else if (typeof err === "string") {
          this.props.enqueueSnackbar(err, { variant: "warning" });
        }
        throw err;
      });
  }

  componentDidMount() {
    this.callPayModule();
    setTimeout(() => {
      this.setState({ timer: true });
    }, 3000);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.timer === false && (
          <CircularProgress className={classes.progress} />
        )}
        <div>
          <Helmet>
            <meta
              charSet="utf-8"
              http-equiv="Content-Security-Policy"
              content="upgrade-insecure-requests"
            />
            <script>
              {`var wpwlOptions = { style: "card", locale: "es" }`}
            </script>
            <script src="./widget.js" type="text/javascript" />
          </Helmet>
          <ScriptTag
            isHydrating={true}
            type="text/javascript"
            src={URL_CARDPAY + `/v1/paymentWidgets.js?checkoutId=${this.state.checkoutIdd}`}
          />
          <form
            action={URL_HOSTING+"paymentStatus"}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(CardPay));
