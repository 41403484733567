import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import logo from "../../assets/oxxopay_brand.png";
import { withSnackbar } from "notistack";
import html2canvas from "html2canvas";
import "./oxxopay.css";
import { URL_BASE, DEFTKN } from "../../constants";

const ZERO = 0;
class OxxoPay extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      amount: "",
      reference: "",
    };
  }

  loguedIn() {
    return !!localStorage.getItem("usr");
  }

  getConektaObj(
    accountId,
    amount,
    concept,
    name,
    email,
    phone,
    level,
    carrer,
    campus
  ) {
    var query = `"action":"generacargo","idCuenta":${accountId},"tipoPago":"oxxo","cantidad":${amount}`;

    if (!this.loguedIn()) {
      query += `,"concepto":"${concept}","nombrePersona":"${name}","email":"${email}","telefono":"${phone}","nivelAcademico":"${level}","carrera":"${carrer}","campus":"${campus}"`;
    }

    fetch(`${URL_BASE}api/conekta?jsonParameters={${query}}`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${
          accountId !== -1 ? localStorage.getItem("tkn") : DEFTKN
        }`,
        Accept: "*/*",
        "Cache-Control": "no-cache",
        Host: "campusvirtualapi.azurewebsites.net",
        "Accept-Encoding": "gzip, deflate",
        Connection: "keep-alive",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          this.props.enqueueSnackbar(
            "No se pudo obtener la referencia en este momento, intentalo más tarde.",
            { variant: "warning", autoHideDuration: 10000 }
          );
        }
        response.json().then((res) => {
          if (typeof res == "string") {
            this.props.enqueueSnackbar(res, {
              variant: "error",
              autoHideDuration: 10000,
            });
            throw res;
          } else if (
            res.charges.data[0].payment_method.reference !== undefined
          ) {
            this.setState({
              amount: res.amount,
              reference: res.charges.data[ZERO].payment_method.reference,
            });
          }
        });
      })
      .catch((err) => {
        if (typeof err === "object") {
          const message500 =
            "Hubo un error en el servidor, intentalo más tarde.";
          this.props.enqueueSnackbar(message500, { variant: "warning" });
        } else if (typeof err === "string") {
          this.props.enqueueSnackbar(err, { variant: "warning" });
        }
        throw err;
      });
  }

  downloadImg() {
    html2canvas(document.querySelector(".opps"), {
      width: 500,
      height: 620,
      y: 370,
    }).then(function (canvas) {
      var a = document.createElement("a");
      a.href = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download = "referencia_de_pago_Oxxo.jpg";
      a.click();
    });
  }

  componentDidMount() {
    this.getConektaObj(
      this.props.idCuenta,
      this.props.amount,
      this.props.concept,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.level,
      this.props.carrer,
      this.props.campus
    );
  }

  render() {
    return (
      <Card>
        <CardContent>
          <div className="opps">
            <div className="opps-header">
              <div className="opps-reminder">
                Ficha digital. No es necesario imprimir.
              </div>
              <div className="opps-info">
                <div className="opps-brand">
                  <img src={logo} alt="OXXOPay" />
                </div>
                <div className="opps-ammount">
                  <h3>Monto a pagar</h3>
                  <h2>
                    ${this.props.amount}
                    <sup>MXN</sup>
                  </h2>
                  <p>
                    OXXO cobrará una comisión adicional al momento de realizar
                    el pago.
                  </p>
                </div>
              </div>
              <div className="opps-reference">
                <h3>Referencia</h3>
                <h1 className="reference-number">{this.state.reference}</h1>
              </div>
            </div>
            <div className="opps-instructions">
              <h3>Instrucciones</h3>
              <ol>
                <li>
                  Acude a la tienda OXXO más cercana.{" "}
                  <a
                    className="linkOxxo"
                    href="https://www.google.com.mx/maps/search/oxxo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Encuéntrala aquí 
                  </a>
                  .
                </li>
                <li>
                  Indica en caja que quieres realizar un pago de{" "}
                  <strong>OXXOPay</strong>.
                </li>
                <li>
                  Dicta al cajero el número de referencia en esta ficha para que
                  tecleé directamete en la pantalla de venta.
                </li>
                <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                <li>
                  Al confirmar tu pago, el cajero te entregará un comprobante
                  impreso.{" "}
                  <strong>
                    En el podrás verificar que se haya realizado correctamente.
                  </strong>{" "}
                  Conserva este comprobante de pago.
                </li>
              </ol>
              <div className="opps-footnote">
                Al completar estos pasos recibirás un correo de{" "}
                <strong>UAL</strong> confirmando tu pago.
              </div>
            </div>
          </div>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={this.downloadImg}
          >
            Descargar ficha de pago
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withSnackbar(OxxoPay);
