import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { URL_HOSTING } from "../constants";

const styles = {
    formControl: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        minWidth: '80%',
        marginBottom: '10px'
    },
    button: {
        margin: '10',
    },
    options: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 15,
        fontWeight: 600,
        flex: 0.2
    },
    title2: {
        fontSize: 14,
        flex: 0.8
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    pos: {
        marginBottom: 12,
    },
    info: {
        display: 'flex',
        flex: 1
    },
    header: {
        textAlign: 'center',
        marginBottom: 12
    }
};

const paymentTypes = [
    { id: 1, code: 'CD', type: 'Tarjeta de credito' },
    { id: 2, code: 'DB', type: 'Tajeta de debito' } 
];

function SimpleCard(props) {
    const { classes } = props;

    const hasError = (field) => {
        if (props.formSubmitted) {
            if (!props[field]) {
                return true;
            }
        }
        return false;
    };

    const hasErrorPaymentType = (field) => {
        if (props.formSubmitted) {
            if (props[field] === '') {
                return true;
            }
        }
        return false;
    };

    const loguedIn = function () {
        return !!localStorage.getItem('usr');
    };

    const [value, setValue] = React.useState('tarjeta');

    function handleChange(event) {
        setValue(event.target.value);
        props.handleChange('paymethod', event.target.value);
    }

    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <h1 className={classes.header}><Typography className={classes.title + ' title-resp'} color="textSecondary" gutterBottom>Confirmar información</Typography></h1>
                    {
                        loguedIn() ? (
                            <div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        nombre del estudiante:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.account.Cuentas[props.step2.concepto].Nombres + ' ' + props.account.Cuentas[props.step2.concepto].Apellido_Paterno}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        correo electrónico:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.account.Cuentas[props.step2.concepto].Lista_Emails}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        Concepto:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.account.Cuentas[props.step2.concepto].Nombre_Cuenta}</small>
                                    </Typography>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        nombre del estudiante:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step1.nombres + ' ' + props.step1.apellidos}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        correo electrónico:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step1.correo}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        Teléfono:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step1.telefono}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        Nivel académico:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step1.nivel}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        Carrera:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step1.carrera}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        Modalidad de estudio:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step1.modalidad}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        Campus:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step1.campus}</small>
                                    </Typography>
                                </div>
                                <div className={classes.info + ' content-resp'}>
                                    <Typography className={classes.title + ' title-resp'} gutterBottom>
                                        Concepto:
                                    </Typography>
                                    <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                                        <small>{props.step2.concepto}</small>
                                    </Typography>
                                </div>
                            </div>
                        )
                    }
                    <div className={classes.info + ' content-resp'}>
                        <Typography className={classes.title + ' title-resp'} gutterBottom>
                            Cantidad:
                        </Typography>
                        <Typography className={classes.title2 + ' title2-resp'} gutterBottom>
                            <small>$ {props.step2.cantidad}</small>
                        </Typography>
                    </div>
                </CardContent>
                <CardActions className={classes.flex}>
                    <FormControl className={classes.formControl} error={hasError('accept')} fullWidth>
                        <div>
                            <Checkbox
                                checked={props.accept}
                                onChange={(e) => props.handleChange('accept', e.target.checked)}
                                value={'accept'}
                                color="primary"
                            />
                            <a href={`${URL_HOSTING}terminos-y-condiciones`} target={'_blank'}>
                                Acepto los términos y condiciones
                            </a>
                            {
                                hasError('accept') && (
                                    <FormHelperText id="component-error-text">Debes aceptar los términos</FormHelperText>
                                )
                            }
                        </div>
                    </FormControl>
                </CardActions>
            </Card>

            <Card className={classes.card}>
                <CardContent>
                    <h1 className={classes.header}><Typography className={classes.title + ' title-resp'} color="textSecondary" gutterBottom>Elige un método de pago</Typography></h1>
                </CardContent>
                <CardActions className={classes.options}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="position" name="position" value={value} onChange={handleChange}
                            row>
                            <FormControlLabel
                                value="tarjeta"
                                control={
                                    <Radio color="primary" />}
                                label="Pago con tarjeta"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="oxxo"
                                control={
                                    <Radio color="primary" />}
                                label="Pago en Oxxo"
                                labelPlacement="end" 
                            />
                        </RadioGroup>
                        {/* {value === 'tarjeta' &&
                            <Grid item container xs={12} lg={12}>
                                <TextField
                                    id="paymentType"
                                    select
                                    error={hasError('paymentType')}
                                    fullWidth
                                    label="Tipo de pago"
                                    className={classes.textField + ' ' + classes.formControl}
                                    value={props.paymentType}
                                    onChange={(e) => props.handleChange('paymentType', e.target.value)}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    helperText={hasErrorPaymentType('paymentType') ? "Este campo es requerido" : "Selecciona tu tipo de tarjeta"}
                                    margin="normal"
                                >
                                    {
                                        Object.values(paymentTypes).map(value => (
                                            <MenuItem key={value.id} value={value.code}>
                                                {value.type}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        }  */}
                    </FormControl>
                </CardActions>
            </Card>
        </>
    );
}

SimpleCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCard);
