import React from 'react';
import OxxoPay from './paymethods/oxxopay';
import CardPay from './paymethods/cardpay';

function step4(props) {
    
    const loguedIn = function() {
        return !!localStorage.getItem('usr');
    };

    const getIdCuenta = function() {
        if(loguedIn() && props.account) {
            return props.account.Cuentas[props.step2.concepto].id_Cuenta;
        }
        return -1; // Cuando no está logueado
    };

    return (    
            (props.step3.paymethod === 'tarjeta') ? 
                ( <CardPay
                    step1={props.step1}
                    step2={props.step2}
                    step3={props.step3}
                    account={props.account}
                /> ) 
                : 
                ( <OxxoPay 
                    idCuenta={getIdCuenta()} 
                    amount={props.step2.cantidad} 
                    concept={props.step2.concepto}
                    name={props.step1.nombres+' '+props.step1.apellidos}
                    email={props.step1.correo}
                    phone={props.step1.telefono}
                    level={props.step1.nivel}
                    carrer={props.step1.carrera}
                    campus={props.step1.campus}
                /> )
    );
}

export default step4;

