import React, { Component } from 'react';
import './App.css';
import Form from './Form';
import { SnackbarProvider } from 'notistack';
import PaymentStatus from './steps/paymentStatus';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

class App extends Component {
    loguedIn = () => {
        return !!localStorage.getItem('usr');
    } 

    state = {
        loguedIn: this.loguedIn()
    };

    sessionChange = () => {
        this.setState({
            loguedIn: this.loguedIn()
        });
    }

    render() {
        return (
            <SnackbarProvider maxSnack={3}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            name="Main"
                            element={<Form loguedIn={this.state.loguedIn} />}
                        ></Route>
                        <Route
                            exact
                            path="/paymentStatus"
                            name="Status payment"
                            element={<PaymentStatus />}
                            ></Route>
                        <Route
                            path="/logout"
                            element={<Navigate to="/" />}
                        ></Route>
                    </Routes>
                </BrowserRouter>
            </SnackbarProvider>
        );
    }
}

export default App;
