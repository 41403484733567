import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';
import { URL_BASE, DEFTKN } from '../constants';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing.unit,
    },
});

class Step1 extends React.Component {

    constructor(...props) {
        super(...props)
        this.state = {
            levels: {},
            carrers: {},
            modes: ['Presencial', 'Sabatina', 'En línea'],
            campuses: {},
            checkoutIdPaymentStatus: {}
        }
    }

    componentDidMount() {
        this.forceUpdate();
        this.requestService('levels', 'listaniveleseducativos')
    }

    requestService = (field, solicitud, idProgAcadem, idNivEdu) => {
        var query = `"nombreSolicitud":"${solicitud}"`
        switch (field) {
            default:
            case 'levels': {
                break;
            }
            case 'carrers': {
                query += `,"idniveleducativo":${idNivEdu}`;
                break;
            }
            case 'campuses': {
                query += `,"idprogramaacademico":${idProgAcadem}`;
                break;
            }
        }

        fetch(`${URL_BASE}api/campus?jsonParameters={${query}}`,
            {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${DEFTKN}`
                }
            })
            .then(response => {
                response.json().then(res => {
                    this.setState({ [field]: res })
                })
            })
    }

    handleChange = async (field, event) => {
        let value = event.target.value;
        await this.props.changeState(field, value);

        if (field === 'nivel') {
            this.getLevelId()
        }
        if (field === 'carrera') {
            this.getProgAcadId()
        }
        if (field === 'campus') {
            this.getCampusId()
        }
    };

    getLevelId() {
        var levelId = 0
        for (let i = 0; i <= this.state.levels.length - 1; i++) {
            if (this.state.levels[i].Nombre_Nivel_Educativo === this.props.state.nivel) {
                levelId = this.state.levels[i].id_Nivel_Educativo;
            }
        }
        this.props.changeState('idniveleducativo', levelId);
        this.requestService('carrers', 'listaprogramasacademicosxniveleducativo', '', levelId)
    }

    getProgAcadId() {
        var progAcadId = 0
        for (let i = 0; i <= this.state.carrers.length - 1; i++) {
            if (this.state.carrers[i].Descripcion === this.props.state.carrera) {
                progAcadId = this.state.carrers[i].id_Programa_Academico;
            }
        }
        this.requestService('campuses', 'listacampixidprogramaacademico', progAcadId)
    }

    getCampusId() {
        var campusId = 0
        for (let i = 0; i <= this.state.campuses.length - 1; i++) {
            if (this.state.campuses[i].Nombre_Campus === this.props.state.campus) {
                campusId = this.state.campuses[i].id_Campus;
            }
        }
        this.props.changeState('idcampus', campusId);
    }

    hasError = (field) => {
        if (this.props.formSubmitted) {
            if (this.props.state[field] === '') {
                return true;
            }
        }
        return false;
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.container}>
                <Grid item container xs={12} lg={6}>
                    <FormControl fullWidth className={classes.formControl} error={this.hasError('nombres')}>
                        <InputLabel htmlFor="component-simple">Nombres</InputLabel>
                        <Input
                            id="component-simple"
                            value={this.props.state.nombres}
                            onChange={this.handleChange.bind(this, 'nombres')}
                            aria-describedby="component-helper-text"
                        />
                        {
                            this.hasError('nombres') ? (
                                <FormHelperText id="component-error-text">Este campo es requerido</FormHelperText>
                            ) : (
                                <FormHelperText id="component-error-text">Escribe tus nombres</FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <FormControl fullWidth className={classes.formControl} error={this.hasError('apellidos')}>
                        <InputLabel htmlFor="component-helper">Apellidos</InputLabel>
                        <Input
                            id="component-helper"
                            value={this.props.state.apellidos}
                            onChange={this.handleChange.bind(this, 'apellidos')}
                            aria-describedby="component-helper-text"
                        />
                        {
                            this.hasError('apellidos') ? (
                                <FormHelperText id="component-error-text">Este campo es requerido</FormHelperText>
                            ) : (
                                <FormHelperText id="component-error-text">Escribe tus apellidos</FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <FormControl fullWidth className={classes.formControl} error={this.hasError('correo')}>
                        <InputLabel htmlFor="component-error">Correo electrónico</InputLabel>
                        <Input
                            id="component-error"
                            value={this.props.state.correo}
                            onChange={this.handleChange.bind(this, 'correo')}
                            aria-describedby="component-error-text"
                        />
                        {
                            this.hasError('correo') ? (
                                <FormHelperText id="component-error-text">Este campo es requerido</FormHelperText>
                            ) : (
                                <FormHelperText id="component-error-text">Escribe tu correo electrónico</FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <FormControl className={classes.formControl} fullWidth error={this.hasError('telefono')}>
                        <InputLabel htmlFor="telefono">Teléfono</InputLabel>
                        <Input
                            value={this.props.state.telefono}
                            onChange={this.handleChange.bind(this, 'telefono')}
                            id="telefono"
                            inputComponent={TextMaskCustom}
                        />
                        {
                            this.hasError('telefono') ? (
                                <FormHelperText id="component-error-text">Este campo es requerido</FormHelperText>
                            ) : (
                                <FormHelperText id="component-error-text">Escribe tu teléfono</FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <TextField
                        id="standard-select-currency"
                        select
                        error={this.hasError('nivel')}
                        fullWidth
                        label="Nivel académico"
                        className={classes.textField + ' ' + classes.formControl}
                        value={this.props.state.nivel}
                        onChange={this.handleChange.bind(this, 'nivel')}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText={this.hasError('nivel') ? "Este campo es requerido" : "Selecciona tu nivel académico"}
                        margin="normal"
                    >
                        {
                            Object.keys(this.state.levels).map(index => (
                                <MenuItem key={index} value={this.state.levels[index].Nombre_Nivel_Educativo}>
                                    {this.state.levels[index].Nombre_Nivel_Educativo}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
                {
                    this.props.state.nivel && (
                        <Grid item container xs={12} lg={6}>
                            <TextField
                                id="standard-select-currency"
                                select
                                error={this.hasError('carrera')}
                                fullWidth
                                label="Carrera"
                                className={classes.textField + ' ' + classes.formControl}
                                value={this.props.state.carrera}
                                onChange={this.handleChange.bind(this, 'carrera')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText={this.hasError('apellidos') ? "Este campo es requerido" : "Selecciona tu carrera"}
                                margin="normal"
                            >
                                {
                                    Object.keys(this.state.carrers).map(index => (
                                        <MenuItem key={index} value={this.state.carrers[index].Descripcion}>
                                            {this.state.carrers[index].Descripcion}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    )
                }
                {
                    this.props.state.carrera && (
                        <Grid item container xs={12} lg={6}>
                            <TextField
                                id="standard-select-currency"
                                select
                                fullWidth
                                error={this.hasError('modalidad')}
                                label="Modalidad de estudio"
                                className={classes.textField + ' ' + classes.formControl}
                                value={this.props.state.modalidad}
                                onChange={this.handleChange.bind(this, 'modalidad')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText={this.hasError('modalidad') ? "Este campo es requerido" : "Selecciona tu modalidad"}
                                margin="normal"
                            >
                                {
                                    Object.keys(this.state.modes).map(d => (
                                        <MenuItem key={d} value={this.state.modes[d]}>
                                            {this.state.modes[d]}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    )
                }
                {
                    this.props.state.modalidad && (
                        <Grid item container xs={12} lg={6}>
                            <TextField
                                id="standard-select-currency"
                                select
                                fullWidth
                                error={this.hasError('campus')}
                                label="Campus"
                                className={classes.textField + ' ' + classes.formControl}
                                value={this.props.state.campus}
                                onChange={this.handleChange.bind(this, 'campus')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText={this.hasError('campus') ? "Este campo es requerido" : "Selecciona tu campus"}
                                margin="normal"
                            >
                                {
                                    Object.keys(this.state.campuses).map(index => (
                                        <MenuItem key={index} value={this.state.campuses[index].Nombre_Campus}>
                                            {this.state.campuses[index].Nombre_Campus}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    )
                }
            </Grid>
        );
    }
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, field, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        [field]: values.value,
                    },
                });
            }}
            thousandSeparator
            prefix="$"
        />
    );
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            showMask={false}
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

Step1.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Step1);
