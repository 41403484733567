//export const URL_BASE = 'https://campusvirtualapi-beta.azurewebsites.net/';
export const URL_BASE = 'https://campusvirtualapi.azurewebsites.net/';
//Token
export const DEFTKN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJDYW1wdXNWaXJ0dWFsQXBpIiwiaWF0IjoiMTU1NTgwMjcwOCIsImV4cCI6IjE4NzE0MjE5MDUiLCJlbWFpbCI6IldlYlBheVBsdXNAc2lzLmVkdS5teCIsImlkX2luc3RpdHVjaW9uIjoiMSIsImlkX3BlcnNvbmEiOiIyIiwiY2FuYWxfY29tdW5pY2FjaW9uIjoiIiwibW9vZGxlX3VzZXJfaWQiOiIiLCJtb29kbGVfdG9rZW4iOiIiLCJyb2wiOiIiLCJhbHVtbm8iOiIiLCJnbXQiOiItNiJ9.fTo8irf2IeUcDR8tOjU81TVVAXgrg_yf9yYZjIM9jbk';

//CardPay Url
export const URL_CARDPAY = 'https://oppwa.com/';

//export const URL_HOSTING = "https://pagos-dev.campusvirtual.mx/";
export const URL_HOSTING = "https://pagos.campusvirtual.mx/";

export default URL_BASE;