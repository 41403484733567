import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import URL_BASE from './constants';
import { Link } from "react-router-dom";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing.unit,
    },
});

class Session extends React.Component {
    state = {
        openSnack: false,
        msgSnack: '',
        open: false,
        username: '',
        password: ''
    };

    handleClickOpen = () => {
        this.setState({ open: !this.state.open });
    };

    handleClose = () => {
        this.setState({
            open: false,
            username: '',
            password: '',
            openSnack: false,
            msgSnack: ''
        });
    };

    hasError = (field) => {
        if (this.props.formSubmitted) {
            if (this.props.state[field] === '') {
                return true;
            }
        }
        return false;
    };

    handleChange = (field, event) => {
        let value = event.target.value;
        let state = this.state;
        state[field] = value;
        this.setState(state);
    };

    login = (e) => {
        const { username, password } = this.state;
        if (username.length === 0) {
            this.setState({
                openSnack: true,
                msgSnack: 'El username es obligatorio'
            });
            return;
        }
        if (password.length === 0) {
            this.setState({
                openSnack: true,
                msgSnack: 'La contraseña es obligatoria'
            });
            return;
        }

        fetch(`${URL_BASE}api/Authenticate/login?username=${username}&password=${btoa(password)}&id_colegio=1`)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('tkn', res.headers.get('token'));
                }
                return res.json();
            })
            .then(json => {
                if (!localStorage.getItem('tkn')) {
                    throw json;
                }
                localStorage.setItem('usr', JSON.stringify(json));
                this.handleClose();
                window.location.replace('');
                //this.props.change();
            })
            .catch(err => {
                this.onOpenSanck(err);
            });
        e.preventDefault()
    }

    loguedIn = () => {
        return !!localStorage.getItem('usr');
    }

    user = () => {
        let ls = JSON.parse(localStorage.getItem('usr'));
        return ls;
    }

    logout = () => {
        localStorage.clear();
        this.setState({
            open: false
        }, this.props.change);
    }

    onOpenSanck = (msg) => {
        this.setState({
            openSnack: true,
            msgSnack: msg
        });
    }

    onCloseSnack = () => {
        this.setState({ openSnack: false });
    }

    componentDidMount = async () => {
        let isLoggedIn = await this.loguedIn()
        if (!isLoggedIn) {
            this.handleClickOpen()
        }
    }

    render() {
        const { classes } = this.props;
        const { openSnack } = this.state;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnack}
                    onClose={this.onCloseSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.msgSnack}</span>}
                />
                {
                    this.loguedIn() ? (
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <h4 style={{ margin: 0, textAlign: 'center' }}>Bienvenido {this.user().Nombres} {this.user().Apellido_Paterno}</h4>
                            <Link to="/logout">
                                <small onClick={this.logout} className="__point">cerrar sesión</small>
                            </Link>
                        </div>
                    ) : (
                        <span onClick={this.handleClickOpen} className="__point">Iniciar sesión</span>
                    )
                }
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                >
                    <form onSubmit={this.login.bind(this)}>
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Inicia sesión"}
                        </DialogTitle>

                        <DialogContent>
                            <small>Recuerda que accedes con tus credenciales de la app.</small>
                            <Grid container className={classes.container}>
                                <Grid item container xs={12}>
                                    <FormControl fullWidth className={classes.formControl} error={this.hasError('username')}>
                                        <InputLabel>Correo</InputLabel>
                                        <Input
                                            value={this.state.username}
                                            onChange={this.handleChange.bind(this, 'username')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container xs={12}>
                                    <FormControl fullWidth className={classes.formControl} error={this.hasError('password')}>
                                        <InputLabel>Contraseña</InputLabel>
                                        <Input
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.handleChange.bind(this, 'password')}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Cancelar
                            </Button>
                            <Button type='submit' variant="contained" color="primary">
                                Iniciar sesión
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(Session);
